<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('setup.changeAccountType') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="4" sm="12">
					<h5 class="text-h5 mb-2">
						{{ $t('setup.chooseAccountType') }}
					</h5>
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('setup.setupExplanation1') }}
					</p>
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('setup.setupExplanation2') }}
					</p>
				</v-col>
				<v-col cols="12" md="8" sm="12">
					<div class="d-flex justify-space-around flex-wrap">
						<v-card flat color="blue darken-1" class="my-1 mx-1 white--text" width="300" @click="selectStudent()" rounded="xl">
							<v-img class="ma-auto" contain height="100" :src="require(`@/assets/images/setup/student1.svg`)" />
							<v-card-title>
								<span class="mx-auto">{{ $t('setup.student') }}</span>
							</v-card-title>
							<v-card-text class="text-justify white--text">
								{{ $t('setup.studentExplained') }}
							</v-card-text>
							<v-card-actions class="white--text">
								<v-spacer />
								<v-icon large v-if="accountType == '1'"> mdi-check </v-icon>
							</v-card-actions>
						</v-card>
						<v-card flat color="teal darken-1" class="my-1 mx-1 white--text" width="300" @click="selectAlumni()" rounded="xl">
							<v-img class="ma-auto" contain height="100" :src="require(`@/assets/images/setup/alumni2.svg`)" />
							<v-card-title>
								<span class="mx-auto">{{ $t('setup.alumni') }}</span>
							</v-card-title>
							<v-card-text class="text-justify white--text">
								{{ $t('setup.alumniExplained') }}
							</v-card-text>
							<v-card-actions class="white--text">
								<v-spacer />
								<v-icon large v-if="accountType == '2'"> mdi-check </v-icon>
							</v-card-actions>
						</v-card>
					</div>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn x-large rounded class="px-4" color="primary" @click="dialog = true">
				{{ $t('setup.changeAccountType') }}
			</v-btn>
		</v-card-actions>

		<v-dialog v-model="dialog" persistent max-width="350">
			<v-card rounded="xl">
				<v-card-title>
					<span class="headline">{{ $t('courses.areYouSure') }}</span>
				</v-card-title>
				<v-card-text class="text-justify" v-if="currentAccountType == '1' && accountType == '2'">
					{{ $t('setup.changeStudentToAlumni') }}
					{{ $t('setup.changeWarningStudentToAlumni') }}
				</v-card-text>
				<v-card-text class="text-justify" v-else-if="currentAccountType == '2' && accountType == '1'">
					{{ $t('setup.changeAlumniToStudent') }}
				</v-card-text>
				<v-card-text class="text-justify" v-else>
					{{ $t('setup.noChangeDetected') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="blue darken-1" text @click="dialog = false"> No </v-btn>
					<v-btn color="error darken-1" text @click="callChangeAccountType()">
						{{ $t('search.continue') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsChangeAccountType',
	props: {
		currentAccountType: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			accountType: '',
		}
	},
	watch: {
		currentAccountType() {
			this.accountType = this.currentAccountType
		},
	},
	created() {
		this.accountType = this.currentAccountType
	},
	methods: {
		selectStudent() {
			this.accountType = '1'
		},
		selectAlumni() {
			this.accountType = '2'
		},
		callChangeAccountType() {
			if (this.accountType != this.currentAccountType) {
				this.changeAccountType({ accountType: this.accountType }).then(() => {
					this.dialog = false
				})
			} else {
				this.dialog = false
			}
		},
		...mapActions('settings', ['changeAccountType']),
	},
}
</script>
